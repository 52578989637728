body {
  font-size: 0.875rem;
  overflow-x: hidden;
}

#root {
  height: 100vh !important;
}

#sidebar-wrapper.hidden {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
  font-size: 1rem;
}

#sidebar-wrapper .list-group-flush .list-group-item {
  border: 0;
}

#page-content-wrapper.hidden {
  min-width: 100vw;
}

#sidebar-wrapper {
  margin-left: 0;
  min-height: 100vh;
}

#page-content-wrapper {
  width: 100%;
  background-color: #f6f6f6;
}

#sidebar-heading .list-group {
  width: 12rem;
  font-size: 1rem;
}

.navbar {
  background-color: #ffffff;
}

.navbar-wrapper {
  display: inline-flex;
  align-items: center;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 40px auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*
   * Navbar
   */

.navbar-brand {
  color: '#fff';
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

/*
  Tables which you can hover and select rows
*/
.selectableTR:hover {
  cursor: pointer;
}

.card-header {
  padding: 0.25rem;
}

.cursor-pointer {
  cursor: pointer;
}

.recharts-responsive-container,
.m-a {
  margin: auto;
}

/*
  xs buttons
*/
.btn-group-xs > .btn,
.btn-xs {
  padding: 0.4rem 0.4rem;
  font-size: 0.8rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.activityDetails::-webkit-scrollbar {
  width: 7px;
}

.activityDetails::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.activityDetails::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
}

.show-on-sm {
  display: none;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color';
}

.form-feedback {
  font-size: 80%;
  margin-top: 0.25rem;
}

.error-border {
  border: 1px solid red;
  border-radius: 0.25em;
}

@media only screen and (max-width: 767px) {
  .hide-on-sm {
    display: none;
  }

  .show-on-sm {
    display: block !important;
  }
}

@media only screen and (max-width: 576px) {
  .hide-on-xs {
    display: none;
  }
}

.update-notification {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  opacity: 1;
}

.update-notification .toast-header {
  background-color: #f8d7da;
  color: #721c24;
  font-weight: bold;
}

#test-site {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
