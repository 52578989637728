.rbc-timeslot-group {
  min-height: 70px;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px;
}

.rbc-allday-cell {
  display: none;
}

.rbc-header {
  border-bottom: none;
}

.rbc-event-content {
  padding-top: 10px;
}
